[data-p~="1:1"] {
	&:checked {
		~ [data-p~="1:2"] {
			background-color: black;

			[data-p~="1:3"] {
				border-color: white;
				transform: translateX(22px);
			}
		}
	}
	&:disabled {
		~ [data-p~="1:2"] {
			background-color: black;
		}
	}
}

[data-p~="8:1"] {
	&:checked {
		~ [data-p~="8:2"] {
			background-color: $color2nd;

			[data-p~="8:3"] {
				border-color: $color-we;
				transform: translateX(18px);
			}
		}
	}
	&:disabled {
		~ [data-p~="8:2"] {
			background-color: $color2nd;
            opacity: .5;
		}
	}
}
