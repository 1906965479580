@font-face {
    font-family: "WorkSans-Regular";
    src: url("/assets/fonts/WorkSans/WorkSans-Regular.ttf") format("truetype");
    font-display: block;
}

@font-face {
    font-family: "WorkSans-Bold";
    src: url("/assets/fonts/WorkSans/WorkSans-Bold.ttf") format("truetype");
    font-display: block;
}



@font-face {
    font-family: "Blinker-Regular";
    src: url("/assets/fonts/Blinker/Blinker-Regular.ttf") format("truetype");
    font-display: block;
}
