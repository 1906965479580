button:focus,
input:focus,
a:focus,
select:focus,
textarea:focus,
[role="button"]:focus {
    outline-style: dashed;
    outline-color:red;
    outline-width: 3px;
}
